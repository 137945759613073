import styled from '@emotion/styled'
import { Button, Title } from '@/components/HomeHero/Item/style.js'

import { mediaMax, mediaMin } from '@/styles/mixins'
import theme from '@/theme'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .swiper {
    width: 100%;

    .swiper-pagination {
      position: absolute;
      left: 0;
      bottom: 5.5rem;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 1.5rem;
      width: 100%;
      padding-inline: 11rem;

      ${mediaMax.md} {
        padding-inline: ${p => p.theme.paddingX.t};
      }

      ${mediaMax.xs} {
        bottom: 3.5rem;
        gap: 3rem;
        padding-inline: ${p => p.theme.paddingX.m};
      }

      .swiper-pagination-bullet {
        position: relative;
        width: 100%;
        height: 10px;
        flex: 1;
        margin: 0;
        border-radius: 0;
        transition: all ease-out 300ms;
        background: transparent;
        overflow: hidden;

        ${mediaMax.xs} {
          width: unset;
          margin-right: unset;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          opacity: 1;
          border-radius: 5rem;
          background-color: ${({ theme }) => theme.colors.white};
          transition: all ease-out 300ms;
          z-index: 20;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          opacity: 0.3;
          background-color: ${({ theme }) => theme.colors.white};
          transition: all ease-out 300ms;
        }

        &:hover {
          &:after, &:before {
            height: 5px;
          }
        }
      }

      .swiper-pagination-bullet-active {
        &::before {
          animation: slide-progress 7.3s linear forwards;

          .swiper-paused & {
            animation-play-state: paused;
          }
        }
      }

      @keyframes slide-progress {
        0% {
          transform: translateX(-100%);
        }

        100% {
          transform: translateX(0);
        }
      }
    }
  }
`

export const LabelLink = styled.a`
  position: absolute;
  top: 7rem;
  left: 0;
  display: inline-flex;
  align-items: center;
  gap: 1.7rem;
  padding-left: 11rem;
  text-decoration: none;
  pointer-events: all;
  cursor: pointer;
  z-index: 2;

  ${mediaMax.md} {
    padding-left: ${p => p.theme.paddingX.t};
  }

  ${mediaMax.xs} {
    top: 3.5rem;
    padding-left: ${p => p.theme.paddingX.m};
  }
`

export const LabelText = styled.h4`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.white};
`

export const LabelIcon = styled.img`
  width: 3.5rem;
  height: 3.5rem;
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  padding-inline: 11rem;
  margin-bottom: 23.2rem;

  ${mediaMax.xl} {
    margin-bottom: 17rem;
  }

  ${mediaMax.md} {
    padding: 0 5rem;
  }

  ${mediaMax.xs} {
    margin-bottom: 12.7rem;
  }
`

export const ContentCategories = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  margin-bottom: 2.8rem;
`

export const ContentCategory = styled.span`
  display: block;
  font-size: 1.6rem;
  color: ${theme.colors.white};
`

export const ContentTitle = styled(Title)`
  max-width: 50vw;
  margin-bottom: 2.8rem;

  ${mediaMax.xs} {
    max-width: 100%;
    margin-bottom: 1.6rem;
  }
`

export const ContentDate = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${theme.colors.white};
  line-height: 1em;
  margin-bottom: 4.7rem;
`

export const ContentButton = styled(Button)`
  border: none;
  padding: 0;
  background-color: transparent !important;

  &:before{
    content: "";
    position: absolute;
    inset: 0;
  }

  div > span {
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 2.4em;
    letter-spacing: -2%;
    padding: 0;
  }

  ${mediaMax.xs} {
    padding: 0 !important;

    span {
      font-size: 1.4rem !important;
    }
  }

  ${mediaMin.md} {
    &:hover {
      background-color: transparent !important;

      span {
        color: ${theme.colors.white} !important;
      }
    }
  }
`
