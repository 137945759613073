import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'

import WysiwygBase from '@/components/Wysiwyg'
import { Wrapper as ArrowWrapper } from '@/components/Block/Sliders/atoms/Arrows/style'

import { StyledGridContainer as GridContainer } from '@/components/Grid'

import { mediaMax, sectionSubtitle, sectionTitle } from '@/styles/mixins'

import 'swiper/css'
import 'swiper/css/virtual'

export const Container = styled.div`
  overflow: hidden;
  padding: 8rem 0;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};

  ${mediaMax.xs} {
    overflow: hidden;
    padding: 0;
    padding-top: 5rem;

    ${GridContainer} {
      padding: 0;
    }
  }
`

export const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10rem;

  ${mediaMax.xs} {
    margin-bottom: 7rem;
    padding-right: 1rem;
  }
`

export const HeadWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

export const Title = styled.h2`
  ${sectionTitle()}
  ${mediaMax.xs} {
    font-size: 3.2rem;
    padding-left: ${({ theme }) => theme.paddingX.m};
  }
`

export const Subtitle = styled.span`
  ${sectionSubtitle()}
  color: ${({ theme }) => theme.colors.lightGreen};
  margin-top: 2.3rem;
  margin-bottom: 3rem;

  ${mediaMax.xs} {
    padding-left: ${({ theme }) => theme.paddingX.m};
  }
`

export const Text = styled(WysiwygBase)`
  width: 80%;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  color: ${({ theme }) => theme.colors.grey};

  ${mediaMax.xs} {
    margin-top: 4rem;
    padding-left: ${({ theme }) => theme.paddingX.m};
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
`

export const SwiperContent = styled(Swiper)`
  width: 100%;
  overflow: visible !important;

  ${mediaMax.xs} {
    padding-top: 3rem !important;
    padding-bottom: 4rem !important;
    overflow: hidden !important;
  }

  .swiper-slide {
    width: auto;

    &:first-of-type {
      margin-left: 5vw;
    }
  }
`

export const ActionWrapper = styled.div`
  width: 100%;
  padding-top: 1.5rem;

  ${ArrowWrapper} {
    &.inverted {
      width: 100%;
      justify-content: center;
      flex-direction: row;
    }
  }

  ${mediaMax.xs} {
    width: 100%;
    display: none;
  }
`

export const LabelLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  text-decoration: none;
  pointer-events: all;
  cursor: pointer;
  z-index: 2;

  h4 {
    color: ${({ theme }) => theme.colors.black};
  }

  img {
    filter: invert(1);
  }

  ${mediaMax.md} {
    padding-left: ${p => p.theme.paddingX.t};
  }

  ${mediaMax.xs} {
    top: 3.5rem;
    padding-left: ${p => p.theme.paddingX.m};
  }
`
